(function ($) {
  Drupal.behaviors.backToTopV1 = {
    attach: function (context) {
      var $backToTop = $('.back-to-top', context);

      $(window).on(
        'scroll',
        _.throttle(function () {
          if ($(window).scrollTop() > 500) {
            $backToTop.addClass('active');
          } else {
            $backToTop.removeClass('active');
          }
        })
      );
      $backToTop.on('click', function () {
        $('html, body').animate(
          {
            scrollTop: 0
          },
          500
        );
      });
    }
  };
})(jQuery);
